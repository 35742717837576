import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import { Router } from 'react-router';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n.js';

import App from './App';
import ScrollToTop from './components/ui/scroll';

import './assets/styles/styles.scss';

import { store, persistor } from './store';
import history from './utils/history';

ReactDOM.render((
    <I18nextProvider i18n={i18n}>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <Router history={history}>
                    <ScrollToTop>
                        <App />
                    </ScrollToTop>
                </Router>
            </PersistGate>
        </Provider>
    </I18nextProvider>
), document.getElementById('root'));
