import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import TotalBox from '../../ui/totalBox';
import Promo from '../../ui/promo';
import InfoCard from '../../ui/infoCard';
import AccountValidation from '../../ui/accountValidation';
import EmptyCart from '../../ui/emptyCart';

import history from '../../../utils/history';
import { renderIcon } from '../../../utils/renderIcon';

import { formatPrice } from '../../../constants';

import '../../../assets/styles/variables.scss';
import './styles.scss';

const Checkout = ({ t, products, cart, changeForm, isPromocodeDiscount, checkbox, countUser }) => {
    const [cartItems, setCartItems] = useState([]);

    useEffect(() => {
        let allProducts = [];

        Object.keys(products).forEach(item => {
            allProducts = [...allProducts, ...products[item].list];
        });

        setCartItems(allProducts.filter(item => cart.indexOf(item.id) !== -1));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cart]);

    return (
        <div className='wrap'>
            <div className='checkout-flex-container'>
                <div className='checkout'>
                    <div className='title'>
                        <div className='back-link' onClick={() => history.goBack()}>
                            <div className='icon'>
                                {renderIcon('arrow-down')}
                            </div>
                            <div className='link'>{t('cart.services_list')}</div>
                        </div>
                        {isPromocodeDiscount && (
                            <div className='coupon'>
                                <Promo/>
                            </div>
                        )}
                    </div>
                    { cartItems.length > 0 && (
                        <div className='list-items'>
                            {cartItems && cartItems.map(item => {
                                return (
                                    <div className='item' key={item.id}>
                                        <div className='description'>
                                            <div className='title-item'>{item.name}</div>
                                            <p className='text'>{item.description}</p>
                                        </div>
                                        <div className='price'>{checkbox ? (isNaN(countUser) ? formatPrice(item.price) : formatPrice(countUser * item.price))
                                            : (
                                                isNaN(countUser) ? formatPrice(item.priceAnnual) : formatPrice(countUser * item.priceAnnual)
                                            )}
                                            <div className='subscription'>/ {t('calculator.month')}.</div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                    { cartItems.length === 0 && <div className='empty-cont'><EmptyCart /></div> }
                    <div className='payment'>
                        <TotalBox />
                    </div>
                </div>
                {changeForm === null ? <AccountValidation /> : <InfoCard cart={cart} />}
            </div>
        </div>
    );
};

Checkout.propTypes = {
    t: PropTypes.func,
    products: PropTypes.array,
    cart: PropTypes.array,
    changeForm: PropTypes.string,
    checkbox: PropTypes.bool,
    isPromocodeDiscount: PropTypes.bool,
};

/**
 *
 * @param state
 * @returns {{changeForm: (null|Requireable<string>), isPromocodeDiscount: (isPromocode|boolean|Requireable<(...args: any[]) => any>), cart: *, products: {}}}
 */
function mapStateToProps(state) {
    return {
        products: state.calculator.products,
        cart: state.calculator.cart,
        changeForm: state.calculator.formEmail,
        checkbox: state.calculator.settings.checkbox,
        countUser: state.calculator.settings.countUser,
        isPromocodeDiscount: state.calculator.isPromocode,
    };
}

export default connect(mapStateToProps, null)(withTranslation()(Checkout));
