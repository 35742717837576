export const FETCH_GET_PRICE = 'FETCH_GET_PRICE';
export const FETCH_GET_PRICE_SUCCESS = 'FETCH_GET_PRICE_SUCCESS';
export const FETCH_GET_PRICE_FAIL = 'FETCH_GET_PRICE_FAIL';

export const FETCH_MENU_URL = 'FETCH_MENU_URL';
export const FETCH_MENU_URL_SUCCESS = 'FETCH_MENU_URL_SUCCESS';
export const FETCH_MENU_URL_FAIL = 'FETCH_MENU_URL_FAIL';

export const FETCH_PRODUCTS = 'FETCH_PRODUCTS';
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';
export const FETCH_PRODUCTS_FAIL = 'FETCH_PRODUCTS_FAIL';

export const FETCH_SETTINGS_CHECKBOX = 'FETCH_SETTINGS_CHECKBOX';
export const FETCH_SETTINGS_USERS = 'FETCH_SETTINGS_USERS';
export const FETCH_SETTINGS_USERS_PERCENT = 'FETCH_SETTINGS_USERS_PERCENT';
export const FETCH_SETTINGS_PRICE_FOR_USERS = 'FETCH_SETTINGS_PRICE_FOR_USERS';

export const FETCH_SETTINGS_POINTS = 'FETCH_SETTINGS_POINTS';
export const FETCH_SETTINGS_MONTHLY = 'FETCH_SETTINGS_MONTHLY';

export const FETCH_ADD_TO_CART = 'FETCH_ADD_TO_CART';
export const FETCH_REMOVE_FROM_CART = 'FETCH_REMOVE_FROM_CART';

export const FETCH_LOGIN = 'FETCH_LOGIN';
export const FETCH_LOGIN_SUCCESS = 'FETCH_LOGIN_SUCCESS';
export const FETCH_LOGIN_FAIL = 'FETCH_LOGIN_FAIL';


export const FETCH_GET_TRIAL = 'FETCH_GET_TRIAL';
export const FETCH_GET_TRIAL_SUCCESS = 'FETCH_GET_TRIAL_SUCCESS';
export const FETCH_GET_TRIAL_FAIL = 'FETCH_GET_TRIAL_FAIL';

export const FETCH_SET_TOTAL_PRICE = 'FETCH_SET_TOTAL_PRICE';

export const FETCH_PROMO = 'FETCH_PROMO';
export const FETCH_PROMO_SUCCESS = 'FETCH_PROMO_SUCCESS';
export const FETCH_PROMO_FAIL = 'FETCH_PROMO_FAIL';

export const FETCH_SAVE_PROMO = 'FETCH_SAVE_PROMO';

export const FETCH_SUBMIT_CART = 'FETCH_SUBMIT_CART';
export const FETCH_SUBMIT_CART_SUCCESS = 'FETCH_SUBMIT_CART_SUCCESS';
export const FETCH_SUBMIT_CART_FAIL = 'FETCH_SUBMIT_CART_FAIL';


export const FETCH_IS_AUTH = 'FETCH_IS_AUTH';
export const FETCH_IS_AUTH_SUCCESS = 'FETCH_IS_AUTH_SUCCESS';
export const FETCH_IS_AUTH_FAIL = 'FETCH_IS_AUTH_FAIL';

export const CLEAR_FORM_EMAIL = 'CLEAR_FORM_EMAIL';
export const FETCH_CLEAR_SUBMIT = 'FETCH_CLEAR_SUBMIT';

export const FETCH_GET_EMPLOYEES = 'FETCH_GET_EMPLOYEES';
export const FETCH_GET_EMPLOYEES_SUCCESS = 'FETCH_GET_EMPLOYEES_SUCCESS';
export const FETCH_GET_EMPLOYEES_FAIL = 'FETCH_GET_EMPLOYEES_FAIL';

export const FETCH_IS_PROMOCODE = 'FETCH_IS_PROMOCODE';
export const FETCH_IS_PROMOCODE_SUCCESS = 'FETCH_IS_PROMOCODE_SUCCESS';
export const FETCH_IS_PROMOCODE_FAIL = 'FETCH_IS_PROMOCODE_FAIL';