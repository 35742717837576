import Dashboard from '../components/pages/dashboard';
import Thank from '../components/pages/thank';
import Checkout from '../components/pages/checkout';

export default [
    {
        path: '/',
        exact: true,
        component: Dashboard,
    },
    {
        path: '/index.html',
        exact: true,
        component: Dashboard,
    },
    {
        path: '/thank-you',
        component: Thank,
    },
    {
        path: '/checkout',
        component: Checkout,
    },
];
