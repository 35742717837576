import React, { useState, useEffect, useRef } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';
import * as PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Range, getTrackBackground } from 'react-range';

import {
    settingsCheckbox,
    settingsUsers,
    settingsUsersPercent,
    settingsPriceForUsers,
    settingsPoints,
    settingsMonthly,
} from '../../../actions/calculator';

import { renderIcon } from '../../../utils/renderIcon';

import { k_format, formatPrice } from '../../../constants';

import './styles.scss';

const Calculator = ({
    flag,
    showMobile,
    modal,
    closeModal,
    t,
    cart,
    totalPrice,
    products,
    actions: {
        settingsCheckbox,
        settingsUsers,
        settingsUsersPercent,
        settingsPriceForUsers,
        settingsPoints,
        settingsMonthly,
    },
    prices,
    settings,
}) => {
    const { countUser, checkbox, countUserPercent, points } = settings;

    const inputRange = useRef(null);
    const [cartPrice, setCartPrice] = useState(0);

    let step = 0;

    const localMas = [0];

    for(let i = 0; i < points.length - 1 ; i++) {
        step = step + 100 / (points.length - 1);
        localMas.push(step);
    }

    useEffect(() => {
        settingsPoints([]);
        settingsMonthly([]);

        const points = [];
        const monthly = [];

        if(checkbox) {
            prices.monthly && prices.monthly.forEach(item => {
                points.push(item.count);
                monthly.push(item);
            });
        }else {
            prices.annual && prices.annual.forEach(item => {
                points.push(item.count);
                monthly.push(item);
            });
        }

        settingsPoints(points);
        settingsMonthly(monthly);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prices]);

    useEffect(() => {
        let allProducts = [];

        let localProductPrice = 0;

        Object.keys(products).forEach(item => {
            allProducts = [...allProducts, ...products[item].list];
        });

        allProducts.filter(item => cart.indexOf(item.id) !== -1).forEach(el => {
            if (checkbox) {
                localProductPrice = localProductPrice + el.price;
            }else {
                localProductPrice = localProductPrice + el.priceAnnual;
            }
        });
        setCartPrice(localProductPrice);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cart, checkbox]);

    const findCountUserPercent = (percent) => {
        let localPercentLine = 0;

        for (let k = 0; k < localMas.length - 1; k++) {
            if(percent[0] >= localMas[k] && percent[0] <= localMas[k + 1] ) {
                localPercentLine = ((percent[0] - localMas[k]) * 100 ) / (localMas[k + 1] - localMas[k]);
                settingsUsers(Math.ceil(((points[k + 1] - points[k]) * localPercentLine) / 100 + points[k]));
            }
        }
    };

    const findCountUser = (param) => {
        let localPercentLine = 0;

        for (let k = 0; k < points.length - 1; k++) {
            if(param >= points[k] && param <= points[k + 1] ) {
                localPercentLine = ((param - points[k]) * 100) / (points[k + 1] - points[k]);
                settingsUsersPercent([Math.ceil(((localPercentLine * (localMas[k + 1] - localMas[k])) / 100) + localMas[k])]);
            }
        }
    };

    const changePrice = (check) => {
        settingsPoints([]);
        settingsMonthly([]);
        const points = [];
        const monthly = [];

        if(check) {
            prices.monthly && prices.monthly.forEach(item => {
                points.push(item.count);
                monthly.push(item);
            });
        }else {
            prices.annual && prices.annual.forEach(item => {
                points.push(item.count);
                monthly.push(item);
            });
        }

        settingsPoints(points);
        settingsMonthly(monthly);
    };

    const changeInput = e => {
        if(parseFloat(e.target.value) < 0 || parseFloat(e.target.value) > points[points.length - 1]) {
            return false;
        }else {
            settingsUsers(Math.trunc(parseFloat(e.target.value)));
            findCountUser(Math.trunc(parseFloat(e.target.value)));
        }
    };

    const handleClick = () => {
        const heightHeader = parseInt(getComputedStyle(document.querySelector('.header')).height);
        const offsetContent = document.querySelector('.content').offsetTop;

        if(modal) {
            closeModal(true);
        }else {
            window.scroll({
                top: offsetContent + heightHeader,
                left: 0,
                behavior: 'smooth',
            });
        }
    };

    return (
        <div className='calculator'>
            <div className='option'>
                <div className='upper-box'>
                    <div className='check-box'>
                        <div
                            className={classNames({ month: true, active: checkbox })}
                            onClick={ () => { settingsCheckbox(true); changePrice(true); }}
                        >
                            {t('calculator.monthly')}
                        </div>
                        <div
                            className={classNames({ annual: true, active: !checkbox })}
                            onClick={ () => { settingsCheckbox(false); changePrice(false); }}
                        >
                            {t('calculator.annual')}
                        </div>
                    </div>
                    <div className='input-box'>
                        <div className='name'>{t('calculator.number_of_user')}:</div>
                        <input
                            type='number'
                            pattern='[1-9]*'
                            min='1'
                            max={points && points[points.length - 1]}
                            value={countUser}
                            onChange={ e => changeInput(e) }
                            onBlur={ e => e.target.value === '' ? settingsUsers(1) : null}
                        />
                    </div>
                </div>
                <div className='box-range'>
                    <div className='range-in'>
                        <Range
                            key={flag}
                            step={0.1}
                            min={0}
                            ref={inputRange}
                            classNames='input-ran'
                            max={100}
                            values={countUserPercent}
                            onChange={values => { settingsUsersPercent(values); findCountUserPercent(values); }}
                            renderTrack={({ props, children }) => (
                                <div
                                    onMouseDown={props.onMouseDown}
                                    onTouchStart={props.onTouchStart}
                                    style={{
                                        ...props.style,
                                        height: '36px',
                                        display: 'flex',
                                        width: '100%',
                                        paddingTop: '26px',
                                    }}
                                >
                                    <div
                                        ref={props.ref}
                                        style={{
                                            height: '5px',
                                            width: '100%',
                                            borderRadius: '4px',
                                            background: getTrackBackground({
                                                values: countUserPercent,
                                                colors: ['#2196F3', '#E4EAEC'],
                                                min: 0,
                                                max: 100,
                                            }),
                                            alignSelf: 'center',
                                        }}
                                    >
                                        {children}
                                    </div>
                                    <div className='white-line'>
                                        {points.map((item, index) => {
                                            return (
                                                <div className='count' key={index}>
                                                    <span>
                                                        {k_format(item)} {index === 0 ? t('calculator.user') : null}
                                                    </span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                            renderThumb={({ props }) => (
                                <div
                                    {...props}
                                    style={{
                                        ...props.style,
                                        height: '20px',
                                        width: '20px',
                                        borderRadius: '3px',
                                        backgroundColor: '#2196F3',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        boxShadow: 'rgb(170, 170, 170) 0px 2px 1px',
                                        outline: 'none',
                                        zIndex: 33,
                                    }}
                                >
                                    <div
                                        style={{
                                            alignItems: 'center',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            height: '20px',
                                            width: '20px',
                                        }}
                                    >
                                        <div
                                            style={{
                                                height: '7px',
                                                width: '1px',
                                                background: '#5FB2F4',
                                                borderRadius: '3px',
                                            }}
                                        />
                                        <div
                                            style={{
                                                height: '12px',
                                                width: '1px',
                                                background: '#5FB2F4',
                                                borderRadius: '3px',
                                                margin: '0 2px',
                                            }}
                                        />
                                        <div
                                            style={{
                                                height: '7px',
                                                width: '1px',
                                                background: '#5FB2F4',
                                                borderRadius: '3px',
                                            }}
                                        />
                                    </div>
                                </div>
                            )}
                        />
                    </div>
                    <div className='info'>
                        {t('calculator.pricing_starts_at')} {formatPrice(cartPrice)} {t('calculator.per_user')}.
                    </div>
                </div>
            </div>
            <div className='total'>
                <div className='box'>
                    <div className='text'>
                        {checkbox ? t('calculator.before_price_monthly') : t('calculator.before_price_annual')}
                    </div>
                    <div className='btn-change' onClick={() => showMobile(false)}>{t('calculator.changeSetup')}</div>
                    <div className='price'><b>{totalPrice.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}</b>/ {t('calculator.month')}.</div>
                    <div className='under'>
                        <div className='first'>
                            {t('calculator.avg')}. <b>{formatPrice(cartPrice)} </b> / {t('calculator.user')} / {t('calculator.month')}.
                        </div>
                        <div className='second'>
                            {t('calculator.for')}&nbsp;
                            {isNaN(countUser) ? 1 : countUser}&nbsp;
                            {t('calculator.users')}
                        </div>
                    </div>
                </div>
                <div className='btn-box'>
                    <div className='price-laptop'><b>{formatPrice(totalPrice)}</b>/ {t('calculator.month')}.</div>
                    <div className='btn' onClick={() => handleClick()}>
                        <div className='icon'>{renderIcon('check')}</div>
                        <div className='name'>{t('calculator.button_check')}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

Calculator.propTypes = {
    t: PropTypes.func,
    flag: PropTypes.bool,
    showMobile: PropTypes.func,
    modal: PropTypes.bool,
    closeModal: PropTypes.func,
    actions: PropTypes.shape({
        settingsCheckbox: PropTypes.func,
        settingsUsers: PropTypes.func,
        settingsUsersPercent: PropTypes.func,
        settingsPriceForUsers: PropTypes.func,
        settingsPoints: PropTypes.func,
        settingsMonthly: PropTypes.func,
    }),
    prices: PropTypes.shape({
        monthly: PropTypes.array,
        annual: PropTypes.array,
    }),
    settings: PropTypes.shape({
        countUser: PropTypes.number,
        checkbox: PropTypes.bool,
        countUserPercent: PropTypes.array,
        priceForUsers: PropTypes.number,
        points: PropTypes.array,
        monthly: PropTypes.array,
    }),
};

/**
 *
 * @param state
 * @returns {{settings: *, prices: (initialState.price|{}|jt.price)}}
 */
function mapStateToProps(state) {
    return {
        prices: state.calculator.price,
        settings: state.calculator.settings,
        products: state.calculator.products,
        cart: state.calculator.cart,
        totalPrice: state.calculator.totalPrice,
    };
}

/**
 *
 * @param dispatch
 * @returns {{actions: ({settingsCheckbox: settingsCheckbox, settingsPriceForUsers: settingsPriceForUsers, settingsMonthly: settingsMonthly, settingsUsersPercent: settingsUsersPercent, settingsPoints: settingsPoints, settingsUsers: settingsUsers}|ActionCreator<any>|ActionCreatorsMapObject<any>)}}
 */
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            settingsCheckbox,
            settingsUsers,
            settingsUsersPercent,
            settingsPriceForUsers,
            settingsPoints,
            settingsMonthly,
        }, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Calculator));
