import React from 'react';
import classNames from 'classnames';
import * as PropTypes from 'prop-types';

import './styles.scss';

const InputBox = ({ setFieldValue, type, name, value, label }) => {
    const handleChange = e => {
        setFieldValue(e.target.name, e.target.value);
    };

    return (
        <div className={classNames({ 'group-field': true, val: value.length > 0 })}>
            <input
                type={type}
                name={name}
                onChange={e => handleChange(e)}
                value={value}
            />
            <label>{label}</label>
        </div>
    );
};

InputBox.propTypes = {
    setFieldValue: PropTypes.func,
    type: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.any,
    label: PropTypes.string,
};

export default InputBox;
