import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';
import * as PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import Menu from '../../ui/modals/menu';

import { getLinkMenu } from '../../../actions/calculator';

import { renderIcon } from '../../../utils/renderIcon';

import logo from '../../../assets/img/logo_mobile.png';

import './styles.scss';

const Header = ({ t, actions: { getLinkMenu }, menu, cart, setShowCart }) => {
    const [animate, setAnimate] = useState(false);
    const [showMenu, setShowMenu] = useState(false);

    useEffect(() => {
        getLinkMenu();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(cart.length > 0 && window.innerWidth < 767) {
            setAnimate(!animate);
            setTimeout(() => {
                setAnimate(false);
            }, 300);
        } else {
            setAnimate(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cart]);

    return (
        <>
            <div className='header'>
                <div className='wrap'>
                    <a href='/' className='logo'>
                        <div className='laptop'>{renderIcon('logo')}</div>
                        <div className='mobile' style={{ backgroundImage: `url(${logo})` }} />
                    </a>
                    <div className='control'>
                        <div className='menu'>
                            <a href={menu[0]} className='item'>{t('header.menu_item_first')}</a>
                            <a href={menu[1]} className='item'>{t('header.menu_item_second')}</a>
                        </div>
                        <div className='btn-free'>{t('header.button')}</div>
                        <div className={classNames({ cart: true, animate })} onClick={() => { setShowMenu(false); setShowCart(true); }}>
                            <div className='cart-animation'>{renderIcon('cart')}</div>
                            <div className='dot-animation' />
                            <div className={classNames({ dot: true, active: cart.length > 0 })} />
                        </div>
                        <div
                            className={classNames({ hamburger: true, 'hamburger--boring': true, 'is-active': showMenu })}
                            onClick={() => setShowMenu(!showMenu) }
                        >
                            <span className='hamburger-box'>
                                <span className='hamburger-inner' />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            {showMenu && <Menu closeModal={setShowMenu} menu={menu}/> }
        </>
    );
};

Header.propTypes = {
    t: PropTypes.func,
    actions: PropTypes.shape({
        getLinkMenu: PropTypes.func,
    }),
    menu: PropTypes.array,
    cart: PropTypes.array,
    setShowCart: PropTypes.func,
};

/**
 *
 * @param state
 * @returns {{menu: Array, cart: *}}
 */
function mapStateToProps(state) {
    return {
        menu: state.calculator.menuLink,
        cart: state.calculator.cart,
    };
}

/**
 *
 * @param dispatch
 * @returns {{actions: ({getLinkMenu: getLinkMenu}|ActionCreator<any>|ActionCreatorsMapObject<any>)}}
 */
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            getLinkMenu,
        }, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Header));
