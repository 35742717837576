import React, { useEffect } from 'react';
import * as PropTypes from 'prop-types';

import './styles.scss';

const isIE = () => {
    // IE 10 and IE 11
    return /Trident\/|MSIE/.test(window.navigator.userAgent);
};

const ScrollWrapper = ({ children }) => {
    const initScroll = () => {
        const customScroll = document.querySelectorAll('.custom-scroll');

        customScroll.forEach((element) => {
            const content = element.querySelector('.wrap');
            const container = document.createElement('div');
            const scroll = document.createElement('span');
            const event = new Event('scroll');

            container.classList.add('custom-scroll__container');
            scroll.classList.add('custom-scroll__thumb');
            container.appendChild(scroll);
            content.parentElement.appendChild(container);

            content.addEventListener('scroll', () => {
                scroll.style.height = container.clientHeight * content.clientHeight / content.scrollHeight + 'px';
                scroll.style.top = container.clientHeight * content.scrollTop / content.scrollHeight + 'px';
            });

            window.addEventListener('resize', content.dispatchEvent.bind(content, event));
            content.dispatchEvent(event);

            scroll.addEventListener('mousedown', (start) => {
                start.preventDefault();
                const y = scroll.offsetTop;
                const onMove = (end) => {
                    const delta = end.pageY - start.pageY;

                    scroll.style.top = Math.min(container.clientHeight - scroll.clientHeight, Math.max(0, y + delta)) + 'px';
                    content.scrollTop = (content.scrollHeight * scroll.offsetTop / container.clientHeight);
                };

                document.addEventListener('mousemove', onMove);
                document.addEventListener('mouseup', () => {
                    document.removeEventListener('mousemove', onMove);
                });
            });
        });
    };

    useEffect(() => {
        if(!isIE()) {
            initScroll();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateCustomScroll = element => {
        const content = element.querySelector('.wrap');
        const scroll = element.querySelector('.custom-scroll__thumb');
        const scrollContainer = element.querySelector('.custom-scroll__container');

        if (scrollContainer) {
            if (content.scrollHeight <= content.clientHeight) {
                scrollContainer.style.display = 'none';
            }else {
                scrollContainer.style.display = 'block';
            }

            scroll.style.height = scrollContainer.clientHeight * content.clientHeight / content.scrollHeight + 'px';
        }
    };

    useEffect(() => {
        if(!isIE()) {
            updateCustomScroll(document.querySelector('.custom-scroll'));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [children]);

    useEffect(() => {
            function updateSize() {
                if(!isIE()) {
                    updateCustomScroll(document.querySelector('.custom-scroll'));
                }
            }

            window.addEventListener('resize', updateSize);
            updateSize();

            return () => window.removeEventListener('resize', updateSize);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className='custom-scroll'>
            <div className='wrap scrolled'>
                {children}
            </div>
        </div>
    );
};

ScrollWrapper.propTypes = {
    children: PropTypes.any,
};

export default ScrollWrapper;
