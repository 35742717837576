import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';
import * as PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { formatPrice } from "../../../../constants";

import Modal from '../../../ui/modal';
import TotalBox from '../../../ui/totalBox';
import Promo from '../../../ui/promo';
import Loader from '../../../ui/loader';
import EmptyCart from '../../../ui/emptyCart';
import ScrollWrapper from '../../../ui/scrollWrapper';

import { removeFromCart, submitCart, clearSubmit } from '../../../../actions/calculator';

import { renderIcon } from '../../../../utils/renderIcon';
import history from '../../../../utils/history';

import './styles.scss';

const Cart = ({
    showCart,
    closeCart,
    t,
    isLogin,
    products,
    settings,
    cart,
    promoCode,
    loadingSubmit,
    errorSubmit,
    checkbox,
    isPromocodeDiscount,
    actions: {
        removeFromCart,
        submitCart,
        clearSubmit,
    },
}) => {
    const { login, link } = isLogin;
    const [cartItems, setCartItems] = useState([]);
    const [animate, setAnimate] = useState(false);

    useEffect(() => {
        const footerHeight = document.querySelector('.modal-footer').clientHeight;
        const titleHeight = document.querySelector('.modal-title').clientHeight;
        const titleMarginBottom = parseInt(getComputedStyle(document.querySelector('.modal-title')).marginBottom);

        if (window.innerWidth < 767) {
            const finalModalFooterHeight = footerHeight + titleHeight + titleMarginBottom;

            document.querySelector('.modal-body').style.height = `calc(100% - (${finalModalFooterHeight}px`;
        }

        setTimeout(() => {
            setAnimate(true);
        }, 300);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (errorSubmit) {
            setTimeout(() => {
                clearSubmit();
            }, 2000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorSubmit]);

    useEffect(() => {
        let allProducts = [];

        Object.keys(products).forEach(item => {
            allProducts = [...allProducts, ...products[item].list];
        });

        setCartItems(allProducts.filter(item => cart.indexOf(item.id) !== -1));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cart]);

    const close = () => {
        setAnimate(false);
        closeCart();
    };

    const callback = () => {
        close();
        setTimeout(() => {
            window.location.hash = 'thank-you';
            // history.push('thank-you');
        }, 400);
    };

    const submit = () => {
        if(login) {
            const listCart = {};

            listCart.planSwitcher = settings.checkbox;
            listCart.quantityOfUsers = settings.countUser;
            listCart.products = cart;
            listCart.promoCode = promoCode;

            submitCart(listCart, callback);
        } else {
            close();
            window.location.hash = 'checkout';
            // history.push('checkout');
        }
    };

    return (
        <Modal flag={showCart} cart={true} animate={animate} closeCart={close}>
            <div className={classNames({ 'modal-cart': true, animate })}>
                <div className='modal-content'>
                    <div className='modal-title'>
                        <span className='desktop-title'>{t('cart.you_have_selected')}:</span>
                        <span className='mobile-title'>{t('cart.cart')}:</span>
                        <div className='icon' onClick={() => close()}>
                            {renderIcon('close')}
                        </div>
                    </div>
                    <div className='modal-body'>
                        {cartItems.length > 0 && (
                            <ScrollWrapper>
                                <div className='list-items'>
                                    {cartItems && cartItems.map(item => {
                                        return (
                                            <div className='item' key={item.id}>
                                                {!item.inCart && (
                                                    <div className='icon' onClick={() => removeFromCart(item.id)}>
                                                        {renderIcon('close')}
                                                    </div>
                                                )}
                                                <div className='box-detail'>
                                                    <div className='name'>{item.name}</div>
                                                    <div className='value'>
                                                        <div className='price'>{
                                                            checkbox ? (isNaN(settings.countUser) ? formatPrice(item.price) : formatPrice(settings.countUser * item.price))
                                                                : (
                                                                    isNaN(settings.countUser) ? formatPrice(item.priceAnnual) : formatPrice(settings.countUser * item.priceAnnual)
                                                                )
                                                        }</div>
                                                        <div className='subscription'>/ {t('calculator.month')}.</div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </ScrollWrapper>
                        )}
                        {cartItems.length === 0 && <EmptyCart /> }
                    </div>
                    <div className='modal-footer'>
                        <div className='total-wrap'>
                            <TotalBox />
                        </div>
                        {isPromocodeDiscount && (
                            <div className='coupon'><Promo /></div>
                        )}
                        {loadingSubmit && (
                            <div className='load-box'>
                                <Loader medium />
                            </div>
                        )}
                        <div className='checkout-btn' onClick={() => { submit(); }}>{t('cart.checkout')}</div>
                        {isLogin && !login && (
                            <div className='subscription'><a href={link}>{t('cart.login')}</a>&nbsp;
                                {t('cart.to_upgrade_or_add_to_your_existing_subscription')}.
                            </div>
                        )}
                        {errorSubmit && <div className='response-submit'>{t('error.something_wrong_please_try_again')}</div>}
                    </div>
                </div>
            </div>
        </Modal>
    );
};

Cart.propTypes = {
    t: PropTypes.func,
    showCart: PropTypes.bool,
    errorSubmit: PropTypes.bool,
    loadingSubmit: PropTypes.bool,
    checkbox: PropTypes.bool,
    closeCart: PropTypes.func,
    isLogin: PropTypes.shape({
        login: PropTypes.bool,
        link: PropTypes.string,
    }),
    products: PropTypes.array,
    cart: PropTypes.array,
    discount: PropTypes.number,
    isPromocodeDiscount: PropTypes.bool,
    actions: PropTypes.shape({
        removeFromCart: PropTypes.func,
        submitCart: PropTypes.func,
        clearSubmit: PropTypes.func,
    }),
};

/**
 *
 * @param state
 * @returns {{isLogin: Requireable<boolean> | initialState.login | {} | Yt.login, settings: *, errorSubmit: Requireable<boolean> | boolean, promoCode: *, cart: *, products: *, loadingSubmit: Requireable<boolean> | boolean | *}}
 */
function mapStateToProps(state) {
    return {
        products: state.calculator.products,
        cart: state.calculator.cart,
        isLogin: state.calculator.login,
        settings: state.calculator.settings,
        promoCode: state.calculator.promoCode,
        loadingSubmit: state.calculator.loadingSubmit,
        errorSubmit: state.calculator.errorSubmit,
        checkbox: state.calculator.settings.checkbox,
        isPromocodeDiscount: state.calculator.isPromocode,
    };
}

/**
 *
 * @param dispatch
 * @returns {{actions: ({removeFromCart: removeFromCart, clearSubmit: clearSubmit, submitCart: submitCart}|ActionCreator<any>|ActionCreatorsMapObject<any>)}}
 */
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            removeFromCart,
            submitCart,
            clearSubmit,
        }, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Cart));



