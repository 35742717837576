import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';

import InputBox from '../../ui/inputForm';
import Loader from '../../ui/loader';

import { isAuth, clearError } from '../../../actions/calculator';

import './styles.scss';

const AccountValidation = ({ t, actions: { isAuth, clearError }, loadingIsAuth, error, isLogin }) => {
    const { login, link } = isLogin;
    const [fixed, setFixed] = useState(false);

    useEffect(() => {
        const calculator = document.querySelector('.checkout .title');
        const cartRight = document.querySelector('.wrap .checkout');
        const stickyCart = document.querySelector('.account-validation');
        const scrollToElem = () => {
            const currentScroll = window.pageYOffset;
            const positionCart = cartRight.getBoundingClientRect().left;

            if (currentScroll > (calculator.offsetTop + calculator.getBoundingClientRect().height) && window.innerWidth >= 1024) {
                setFixed(true);
                stickyCart.setAttribute('style', `right: ${positionCart}px`);
            } else {
                setFixed(false);
                stickyCart.removeAttribute('style');
            }
        };

        window.addEventListener('scroll', scrollToElem);

        return () => window.removeEventListener('scroll', scrollToElem);
    }, []);

    useEffect(() => {
        if (error !== null) {
            setTimeout(() => {
                clearError();
            }, 2000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    return (
        <div className={classNames({ 'account-validation': true, fixed })}>
            <div className='name'>{t('account.account_validation')}</div>
            <div className='account-box'>
                <Formik
                    initialValues={{ email: '' }}
                    validationSchema={Yup.object().shape({
                        email: Yup.string()
                            .email(t('account.invalid_email'))
                            .required(t('account.required')),
                    })
                    }
                    onSubmit={(values, actions) => {
                        isAuth(values);
                        actions.setSubmitting(false);
                    }}
                >
                    {({ handleSubmit, handleChange, handleBlur, values, errors, setFieldValue }) => (
                        <form onSubmit={handleSubmit}>
                            <div className='name-form'>{t('account.enter_email_to_proceed')}</div>
                            <div className='input-flex-container'>
                                <div className='input-box'>
                                    <InputBox
                                        setFieldValue={setFieldValue}
                                        type='email'
                                        name='email'
                                        onChange={handleChange}
                                        value={values.email}
                                        label='E-mail'
                                    />
                                    {errors.email && <div className='field-error'>{errors.email}</div>}
                                </div>
                                {loadingIsAuth ? (
                                    <div className='load-box'>
                                        <Loader medium/>
                                    </div>
                                ) : (
                                    <button type='submit' className='btn-submit'>{t('account.proceed_next')}</button>
                                )}
                                {error && <div className='info-submit'>{t(error)}</div>}
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
            {isLogin && !login && (
                <div className='under-text'>
                    <a href={link}>{t('cart.login')}</a>&nbsp;
                    {t('cart.to_upgrade_or_add_to_your_existing_subscription')}.
                </div>
            )}
        </div>
    );
};

AccountValidation.propTypes = {
    t: PropTypes.func,
    actions: PropTypes.shape({
        isAuth: PropTypes.func,
        clearError: PropTypes.func,
    }),
    loadingIsAuth: PropTypes.bool,
    error: PropTypes.string,
    isLogin: PropTypes.shape({
        login: PropTypes.bool,
        link: PropTypes.string,
    }),
};

/**
 *
 * @param state
 * @returns {{loadingIsAuth: Requireable<boolean> | boolean, isLogin: Requireable<boolean> | initialState.login | {} | Yt.login, error: null}}
 */
function mapStateToProps(state) {
    return {
        loadingIsAuth: state.calculator.loadingIsAuth,
        error: state.calculator.errorFormEmail,
        isLogin: state.calculator.login,
    };
}

/**
 *
 * @param dispatch
 * @returns {{actions: ({isAuth: isAuth, clearError: clearError}|ActionCreator<any>|ActionCreatorsMapObject<any>)}}
 */
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            isAuth,
            clearError,
        }, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AccountValidation));
