import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';
import * as PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { formatPrice } from '../../../constants';

import { addToCart } from '../../../actions/calculator';

import { renderIcon } from '../../../utils/renderIcon';

import './styles.scss';

const ProductList = ({ t, product, actions: { addToCart } , cart, settings, checkbox }) => {
    useEffect(() => {
        product.map(item => item.inCart ? addToCart(item.id) : null);
    }, []);

    return (
        <>
            {product.map((item, index) => {
                return (
                    <div className={classNames({ 'item-product': true, active: (cart.indexOf(item.id) !== -1 || item.inCart) })} key={index}>
                        <div className='photo'>
                            <div className='el' style={{ backgroundImage: `url(${item.icon})` }} />
                            <div className='el-hover' style={{ backgroundImage: `url(${item.icon_active})` }} />
                        </div>
                        <div className='detail'>
                            <div className='info'>
                                <div className='name'>{item.name}</div>
                                <div className='description'>{item.description}</div>
                            </div>
                            <div className='option' onClick={() => addToCart(item.id)}>
                                <div className='price'><b>{checkbox ? formatPrice(item.price) : formatPrice(item.priceAnnual)}</b>&nbsp;/ {t('calculator.month')}</div>
                                <div className='by'>+<span className='mark'>{renderIcon('mark')}</span></div>
                            </div>
                        </div>
                    </div>
                );
            })}
        </>
    );
};

ProductList.propTypes = {
    t: PropTypes.func,
    product: PropTypes.array,
    cart: PropTypes.array,
    actions: PropTypes.shape({
        addToCart: PropTypes.func,
    }),
};

/**
 *
 * @param state
 * @returns {{cart: *}}
 */
function mapStateToProps(state) {
    return {
        cart: state.calculator.cart,
        checkbox: state.calculator.settings.checkbox,
    };
}

/**
 *
 * @param dispatch
 * @returns {{actions: ({addToCart: addToCart}|ActionCreator<any>|ActionCreatorsMapObject<any>)}}
 */
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            addToCart,
        }, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ProductList));
