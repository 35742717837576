import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import rootReducer from '../reducers/rootReducer';

const reducer = persistReducer({
    key: 'root',
    storage,
}, rootReducer);

const store = createStore(reducer, applyMiddleware(thunkMiddleware));
const persistor = persistStore(store);

export { store, persistor };
