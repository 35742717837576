import React from 'react';
import classNames from 'classnames';
import * as PropTypes from 'prop-types';

import './styles.scss';

const Loader = ({ small, medium, smaller }) => {
    return (
        <div className={classNames({ 'lds-dual-ring': true, small, medium, smaller })} />
    );
};

Loader.propTypes = {
    small: PropTypes.bool,
    medium: PropTypes.bool,
    smaller: PropTypes.bool,
};

export default Loader;
