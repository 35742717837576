import React, { useState } from 'react';
import { Switch, Route, HashRouter } from 'react-router-dom';

import routes from './constants/routes';
import Header from './components/ui/header';
import Cart from './components/ui/modals/cart';

const App = () => {
    const [showCart, setShowCart] = useState(false);
    const closeCart = () => {
        setTimeout(() => {
            setShowCart(false);
        }, 300);
    };

    return (
        <>
            <Header setShowCart={setShowCart}/>
            {showCart && <Cart showCart={showCart} closeCart={closeCart} />}
            <HashRouter>
            <Switch>
                {routes.map(({ path, component, exact }, index) => (
                    <Route
                        key={index}
                        path={path}
                        component={component}
                        exact={exact || false}
                    />
                ))}
            </Switch>
            </HashRouter>
        </>
    );
};

export default App;
