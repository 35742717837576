import React from 'react';
import { withTranslation } from 'react-i18next';
import * as PropTypes from 'prop-types';

import { renderIcon } from '../../../utils/renderIcon';

import './styles.scss';

const EmptyCart = ({ t }) => {
    return (
        <div className='empty-cart'>
            <div className='icon-box'>
                <div className='icon'>
                    {renderIcon('cart')}
                </div>
                <div className='label-icon'>{t('cart.no_items_added_yet')}</div>
            </div>
        </div>
    );
};

EmptyCart.propTypes = {
    t: PropTypes.func,
};

export default withTranslation()(EmptyCart);
