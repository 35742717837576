import i18n from 'i18next';
import { LOCALE, LOCALES } from './constants';
import backend from 'i18next-xhr-backend';

export const checkCurrentLocale = () => {
  // Detect language based on browser's language
  let detectLanguage = '';

  if(navigator.userAgent.match(/MSIE 10/)) {
    detectLanguage = navigator.userLanguage.substring(0, 2);
  }else {
    detectLanguage = window.navigator.language.substring(0, 2);
  }


  let language = (detectLanguage === LOCALE.DE)
    ? LOCALE.DE
    : LOCALE.EN;
  const url = window.location.href;

  // Force show specific language
  if (url.indexOf(`lang=${LOCALE.EN}`) !== -1) { language = LOCALE.EN; }
  if (url.indexOf(`lang=${LOCALE.DE}`) !== -1) { language = LOCALE.DE; }

  document.documentElement.lang = language;
  document.documentElement.setAttribute('data-browserLocale', window.navigator.language);
  document.documentElement.setAttribute('data-browserLang', detectLanguage);

  return language;
};

i18n.use(backend).init({
  interpolation: {
    escapeValue: false,
  },
  lng: checkCurrentLocale(),
  fallbackLng: LOCALE.DE,
  whitelist: LOCALES,
  backend: {
    loadPath: '/locales/{{lng}}.json',
  },
  react: {
    useSuspense: false,
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
  },
});

export default i18n;
