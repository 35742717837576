import React from 'react';
import * as PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import './styles.scss';

const Thank = ({ t }) => {
    return (
        <div className='thank-you'>
            <h1>{t('thankPage.title')}</h1>
            <p className='text'>{t('thankPage.description')}</p>
            <a href='https://club.kameon.de/' className='link'>{t('thankPage.link')}</a>
        </div>
    );
};

Thank.propTypes = {
    t: PropTypes.func,
};

export default withTranslation()(Thank);
