import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import * as PropTypes from 'prop-types';

import Calculator from '../../../ui/calculator';

import { renderIcon } from '../../../../utils/renderIcon';

import './styles.scss';

const ModalCalculator = ({ modal }) => {
    const [flag, setFlag] = useState(true);
    const [show, setShow] = useState(false);

    useEffect(() => {
        const calculator = document.querySelector('.main-tabs .calculator');
        const scrollToElem = () => {
            const currentScroll = window.pageYOffset;

            if(currentScroll > (calculator.offsetTop + calculator.getBoundingClientRect().height)) {
                setShow(true);
            }else {
                setShow(false);
                setFlag(true);
            }
        };

        window.addEventListener('scroll', scrollToElem);

        return () => window.removeEventListener('scroll', scrollToElem);
    }, []);

    return (
        <>
            <div className={classNames({ 'modal-calculator': true, close: flag , show })}>
                <div className='wrap'>
                    <div className={classNames({ 'arrow-down': true, esc: !flag })} onClick={() => setFlag(!flag)}>
                        <span>{renderIcon('arrow-down')}</span>
                        <span>{renderIcon('close')}</span>
                    </div>
                    <Calculator showMobile={setFlag} flag={flag} modal={modal} closeModal={setFlag}/>
                </div>
            </div>
            <div className={classNames({ bg: true, close: flag })} onClick={() => { setFlag(true); }} />
        </>
    );
};

ModalCalculator.propTypes = {
    modal: PropTypes.bool,
};

export default ModalCalculator;
