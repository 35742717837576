import fetch from 'cross-fetch';

/**
 *
 * @param endPoint
 * @param method
 * @param data
 * @returns {Promise<Response | never>}
 */
function callApi(endPoint, method, data) {
    const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    };

    return fetch(`${process.env.REACT_APP_HOST}${endPoint}`,{
        method,
        headers,
        body: JSON.stringify(data),
    }).then(response => {
        if(response.status === 200) {
            return response.json();
        }else {
            return response.json().then(json => Promise.reject(json));
        }
    });
}

export {
    callApi,
};
