import React from 'react';

import { ReactComponent as LogoIcon } from '../assets/svg/logo.svg';
import { ReactComponent as CartIcon } from '../assets/svg/cart.svg';
import { ReactComponent as LogoMobileIcon } from '../assets/svg/logo_mobile.svg';
import { ReactComponent as CloudIcon } from '../assets/svg/cloud.svg';
import { ReactComponent as DnsIcon } from '../assets/svg/dns.svg';
import { ReactComponent as CheckIcon } from '../assets/svg/check.svg';
import { ReactComponent as MarkIcon } from '../assets/svg/mark.svg';
import { ReactComponent as ArrowDownIcon } from '../assets/svg/arrow_down.svg';
import { ReactComponent as CloseIcon } from '../assets/svg/close.svg';
import { ReactComponent as PromoIcon } from '../assets/svg/promo.svg';
import { ReactComponent as DiscountIcon } from '../assets/svg/discount.svg';
import { ReactComponent as CreditIcon } from '../assets/svg/credit.svg';
import { ReactComponent as InvoiceIcon } from '../assets/svg/invoice.svg';
import { ReactComponent as PayPalIcon } from '../assets/svg/paypal.svg';

export const renderIcon = icon => {
    switch (icon) {
        case 'logo':
            return <LogoIcon />;
        case 'logo-mobile':
            return <LogoMobileIcon />;
        case 'cart':
            return <CartIcon />;
        case 'cloud':
            return <CloudIcon />;
        case 'dns':
            return <DnsIcon />;
        case 'check':
            return <CheckIcon />;
        case 'mark':
            return <MarkIcon />;
        case 'arrow-down':
            return <ArrowDownIcon />;
        case 'close':
            return <CloseIcon />;
        case 'promo':
            return <PromoIcon />;
        case 'discount':
            return <DiscountIcon />;
        case 'credit':
            return <CreditIcon />;
        case 'invoice':
            return <InvoiceIcon />;
        case 'payPal':
            return <PayPalIcon />;
        default:
            return <LogoIcon />;
    }
};
