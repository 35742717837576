import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';

import Modal from '../../modal';

import './styles.scss';

const Menu = ({ t, menu }) => {
    const [flag, setFlag] = useState(false);

    useEffect(() => {
        setFlag(!flag);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return (
        <Modal flag={flag}>
            <div className={classNames({ modal: true, open: flag })}>
                <div className='menu'>
                    <a href={menu[0]} className='item'>{t('header.menu_item_first')}</a>
                    <a href={menu[1]} className='item'>{t('header.menu_item_second')}</a>
                    <div className='btn-free'>{t('header.button')}</div>
                </div>
            </div>
        </Modal>
    );
};

Menu.propTypes = {
    closeModal: PropTypes.func,
    t: PropTypes.func,
};

export default withTranslation()(Menu);
