import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';
import * as PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import Loader from '../../ui/loader';

import { isValidPromo } from '../../../actions/calculator';

import { renderIcon } from '../../../utils/renderIcon';

import './styles.scss';

const Promo = ({ t, actions: { isValidPromo }, promoCode, loading, discount }) => {
    const handlePromo = e => {
        isValidPromo(e.target.value);
    };

    return (
        <div className={classNames({ 'input-box': true, promo: discount })}>
            <div className='icon'>{renderIcon('promo')}</div>
            <input placeholder={t('cart.enter_promocode')} onChange={e => handlePromo(e)} value={promoCode || ''} />
            {loading && <div className='loader'><Loader small={true} /></div>}
            {!loading && discount && <div className='icon-success'>{renderIcon('mark')}</div>}
        </div>
    );
};

Promo.propTypes = {
    t: PropTypes.func,
    loading: PropTypes.bool,
    discount: PropTypes.number,
    actions: PropTypes.shape({
        isValidPromo: PropTypes.func,
    }),
    promoCode: PropTypes.string,
};

/**
 *
 * @param state
 * @returns {{discount: Requireable<number> | null, promoCode: *, loading: boolean}}
 */
function mapStateToProps(state) {
    return {
        promoCode: state.calculator.promoCode,
        loading: state.calculator.loadingPromoCode,
        discount: state.calculator.discount,
    };
}

/**
 *
 * @param dispatch
 * @returns {{actions: ({isValidPromo: isValidPromo}|ActionCreator<any>|ActionCreatorsMapObject<any>)}}
 */
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            isValidPromo,
        }, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Promo));
