import React from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';
import * as PropTypes from 'prop-types';

import './styles.scss';

const Modal = ({ children, flag, cart, animate, closeCart }) => {
    const close = () => {
        closeCart();
    };

    return createPortal(
        (
            <div className={classNames({ 'bg-overlay': true, full: cart , animate })}>
                <div className='bg' onClick={() => close()}/>
                {children}
            </div>
        ),
        document.getElementById('modal')
    );
};

Modal.propTypes = {
    children: PropTypes.any,
    flag: PropTypes.bool,
    cart: PropTypes.bool,
    animate: PropTypes.bool,
    closeCart: PropTypes.func,
};

export default Modal;
