import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Formik, Field } from 'formik';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import * as PropTypes from 'prop-types';
import Select from 'react-select';

import Loader from '../../ui/loader';
import ScrollWrapper from '../../ui/scrollWrapper';

import { getEmployees, submitCart, clearSubmit } from '../../../actions/calculator';

import history from '../../../utils/history';
import { renderIcon } from '../../../utils/renderIcon';

import './styles.scss';

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

class Wizard extends React.Component {
    static Page = ({ children, parentState }) => {
        return children(parentState);
    };

    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            values: props.initialValues,
        };
    }

    next = values =>
        this.setState(state => ({
            page: Math.min(state.page + 1, this.props.children.length - 1),
            values,
        }));

    previous = () =>
        this.setState(state => ({
            page: Math.max(state.page - 1, 0),
        }));

    validate = values => {
        const activePage = React.Children.toArray(this.props.children)[this.state.page];

        return activePage.props.validate ? activePage.props.validate(values) : {};
    };

    handleSubmit = (values, bag) => {
        const { children, onSubmit } = this.props;
        const { page } = this.state;
        const isLastPage = page === React.Children.count(children) - 1;

        if (isLastPage) {
            return onSubmit(values, bag);
        } else {
            this.next(values);
            bag.setSubmitting(false);
        }
    };

    render() {
        const { children, t, loadingSubmit } = this.props;
        const { page, values } = this.state;
        const activePage = React.Children.toArray(children)[page];
        const isLastPage = page === React.Children.count(children) - 1;

        return (
            <Formik
                initialValues={values}
                enableReinitialize={false}
                // validateOnChange={true}
                validate={this.validate}
                onSubmit={this.handleSubmit}
                >
                {props => (
                    <form onSubmit={props.handleSubmit}>
                        {React.cloneElement(activePage, { parentState: { ...props } })}
                        <div className={classNames({ 'bottom-navigation': true, 'step-two': page === 1 })}>
                            <button onClick={this.previous} type='button' className={classNames({ grey: true, disable: page === 0 })}>
                                {t('account.back')}
                            </button>
                            {!isLastPage && <button type='submit'>{t('account.next')}</button>}
                            {loadingSubmit && (
                                <div className='load-box'>
                                    <Loader smaller />
                                </div>
                            )}
                            {isLastPage && !loadingSubmit && (
                                <button type='submit' disabled={props.isSubmitting}>
                                    Submit
                                </button>
                            )}
                        </div>
                    </form>
                )}
            </Formik>
        );
    }
}

const limit = (val, max) => {
    if (val.length === 1 && val[0] > max[0]) {
        val = '0' + val;
    }

    if (val.length === 2) {
        if (Number(val) === 0) {
            val = '01';
        } else if (val > max) {
            val = max;
        }
    }

    return val;
};

const cardExpiry = val => {
    const month = limit(val.substring(0, 2), '12');
    const year = val.substring(2, 4);

    return month + (year.length ? '/' + year : '');
};

const InfoCard = ({
    t,
    formEmail,
    actions: {
        getEmployees,
        submitCart,
        clearSubmit,
    },
    employees,
    cart,
    settings,
    promoCode,
    loadingSubmit,
    errorSubmit,
}) => {
    const [fixed, setFixed] = useState(false);
    const [options, setOptions] = useState([]);
    const [activeSelect, setActiveSelect] = useState(false);
    const [step, setStep] = useState(1);
    const [paymentMethod, SetPaymentMethod] = useState('');

    useEffect(() => {
        const calculator = document.querySelector('.checkout .title');
        const cartRight = document.querySelector('.wrap .checkout');
        const stickyCart = document.querySelector('.account-card');
        const distanceAbove = 30;
        const scrollToElem = () => {
            const currentScroll = window.pageYOffset;
            const positionCart = cartRight.getBoundingClientRect().left;

            if (currentScroll > (calculator.offsetTop + calculator.getBoundingClientRect().height - distanceAbove) && window.innerWidth >= 1024) {
                setFixed(true);
                stickyCart.setAttribute('style', `right: ${positionCart}px`);
            } else {
                setFixed(false);
                stickyCart.removeAttribute('style');
            }
        };

        window.addEventListener('scroll', scrollToElem);

        return () => window.removeEventListener('scroll', scrollToElem);
    }, []);

    useEffect(() => {
        const localOptions = [];

        Object.keys(employees).forEach(el => {
            localOptions.push({ value: el, label: employees[el] });
        });

        setOptions(localOptions);
    }, [employees]);

    useEffect(() => {
        getEmployees();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(step === 1) {
            SetPaymentMethod('credit_card');
        }else if (step === 2) {
            SetPaymentMethod('invoice');
        }else if (step === 3) {
            SetPaymentMethod('paypal');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step]);

    useEffect(() => {
        if (errorSubmit) {
            setTimeout(() => {
                clearSubmit();
            }, 2000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorSubmit]);

    const callback = () => {
        setTimeout(() => {
            window.location.hash = 'thank-you';
            // history.push('thank-you');
        }, 400);
    };

    return (
        <div className={classNames({ 'account-card': true, fixed })}>
            <div className='title-form'>{t('account.account_details')}</div>
            <div className='account-box'>
                <Wizard
                    initialValues={{
                        firstName: '',
                        lastName: '',
                        email: formEmail || '',
                        company: '',
                        employees: '',
                        payment_firstName: '',
                        payment_lastName: '',
                        payment_cardNumber: '',
                        payment_expiry: '',
                        payment_cvv: '',
                        payment_address_line_one: '',
                        payment_address_line_two: '',
                        payment_city: '',
                        payment_zip: '',
                        confirm: false,
                    }}
                    onSubmit={values => {
                        sleep(300).then(() => {
                            const listCart = {};

                            listCart.isMonthly = settings.checkbox;
                            listCart.quantityOfUsers = settings.countUser;
                            listCart.products = cart;
                            listCart.promoCode = promoCode;
                            listCart.account = values;
                            listCart.payment_method = paymentMethod;

                            submitCart(listCart, callback);
                        });
                    }}
                    t={t}
                    loadingSubmit={loadingSubmit}
                >
                    <Wizard.Page>
                        {props => (
                            <React.Fragment>
                                <div className='form-group'>
                                    <div className={classNames({ 'field-inp': true, val: props.values.firstName })}>
                                        <Field
                                            component='input'
                                            id='firstName'
                                            name='firstName'
                                            type='text'
                                        />
                                        <label>{t('account.first_name')}</label>
                                    </div>
                                    <div className={classNames({ 'field-inp': true, val: props.values.lastName })}>
                                        <Field
                                            component='input'
                                            id='lastName'
                                            name='lastName'
                                            type='text'
                                        />
                                        <label>{t('account.last_name')}</label>
                                    </div>
                                    <div className={classNames({ 'field-inp': true, full: true, val: props.values.email })}>
                                        <Field
                                            component='input'
                                            id='email'
                                            name='email'
                                            type='text'
                                        />
                                        <label>{t('account.email')}</label>
                                    </div>
                                    <div className={classNames({ 'field-inp': true, full: true, val: props.values.company })}>
                                        <Field
                                            component='input'
                                            id='company'
                                            name='company'
                                            type='text'
                                        />
                                        <label>{t('account.company')}</label>
                                    </div>
                                    <div className={classNames({ 'custom-select': true, full: true, val: props.values.employees })}>
                                        <Select
                                            onFocus={() => setActiveSelect(!activeSelect)}
                                            onBlur={() => setActiveSelect(!activeSelect)}
                                            onChange={e => props.setFieldValue('employees', e)}
                                            options={options}
                                            isSearchable={false}
                                            components={{
                                                IndicatorSeparator: () => null,
                                            }}
                                            {...props}
                                            placeholder={''}
                                            styles={{
                                                control: (base, state) => ({
                                                    ...base,
                                                    borderStyle: 'none',
                                                    backgroundColor: 'transparent',
                                                    transition: 'all .3s ease-in',
                                                    borderBottom: state.isFocused ? '2px solid #2196F3' : '2px solid #E4EAEC',
                                                    borderRadius: 'none',
                                                    paddingTop: '13px',
                                                    paddingLeft: '3px',
                                                    borderColor: state.isFocused ? 'none' : 'none',
                                                    boxShadow: state.isFocused ? 'none' : 'none',
                                                    fontSize: '13px',
                                                    '&:hover': {
                                                        borderBottom: '2px solid #E4EAEC',
                                                        cursor: 'text',
                                                    },
                                                }),
                                                menu: (base) => ({
                                                   ...base,
                                                   color: '#424242',
                                                   zIndex: '10',
                                                }),
                                                singleValue: (base) => ({
                                                    ...base,
                                                    color: '#424242',
                                                    fontSize: '15px',
                                                }),
                                            }}
                                        />
                                        <label className={classNames({ 'activeSelect': activeSelect })}>{t('account.employees')}</label>
                                    </div>
                                </div>
                            </React.Fragment>
                        )}
                    </Wizard.Page>
                    <Wizard.Page
                        validate={values => {
                            return sleep(500).then(() => {
                                const errors = {};

                                if(step === 1) {
                                    if (!values.payment_cvv || values.payment_cvv.replace(/\s/g, '').length < 3) {
                                        errors.payment_cvv = t('account.required');
                                    }
                                    if (!values.payment_expiry || values.payment_expiry.replace(/\s/g, '').length < 5) {
                                        errors.payment_expiry = t('account.required');
                                    }
                                    if (!values.payment_cardNumber || values.payment_cardNumber.replace(/\s/g, '').length !== 16) {
                                        errors.payment_cardNumber = t('account.required');
                                    }
                                }

                                if(step !== 3) {
                                    if (!values.payment_firstName || values.payment_firstName.replace(/\s/g, '').length < 3) {
                                        errors.payment_firstName = t('account.required');
                                    }
                                    if (!values.payment_lastName || values.payment_lastName.replace(/\s/g, '').length < 3) {
                                        errors.payment_lastName = t('account.required');
                                    }

                                    if (!values.payment_address_line_one || values.payment_address_line_one.replace(/\s/g, '').length <= 3) {
                                        errors.payment_address_line_one = t('account.required');
                                    }

                                    if (!values.payment_city || values.payment_city.replace(/\s/g, '').length <= 3) {
                                        errors.payment_city = t('account.required');
                                    }
                                    if (!values.payment_zip || values.payment_zip.replace(/\s/g, '').length <= 3) {
                                        errors.payment_zip = t('account.required');
                                    }
                                    if (!values.confirm) {
                                        errors.confirm = t('account.required');
                                    }
                                }

                                return errors;
                            });
                        }}
                    >
                        {props => (
                            <React.Fragment>
                                <ScrollWrapper>
                                    <div className='scroll-block'>
                                        <div className='tabs-section'>
                                            <div className={classNames({ label: true, active: step === 1 })} onClick={() => setStep(1)}>
                                                <div className='icon'>{renderIcon('credit')}</div>
                                                <div className='text'>Credit Card</div>
                                            </div>
                                            <div className={classNames({ label: true, active: step === 2 })} onClick={() => setStep(2)}>
                                                <div className='icon'>{renderIcon('invoice')}</div>
                                                <div className='text'>Invoice</div>
                                            </div>
                                            <div className={classNames({ label: true, active: step === 3 })} onClick={() => setStep(3)}>
                                                <div className='icon'>{renderIcon('payPal')}</div>
                                                <div className='text'>PayPal</div>
                                            </div>
                                        </div>
                                        {step !== 3 && (
                                            <>
                                            <div className={classNames({ 'field-inp': true, val: props.values.payment_firstName })}>
                                                <Field
                                                    component='input'
                                                    id='payment_firstName'
                                                    name='payment_firstName'
                                                    type='text'
                                                />
                                                <label>{t('account.payment_firstName')}<sup>*</sup></label>
                                                <span className='error-field'>{props.errors['payment_firstName']}</span>
                                            </div>
                                            <div className={classNames({ 'field-inp': true, val: props.values.payment_lastName })}>
                                                <Field
                                                    component='input'
                                                    id='payment_lastName'
                                                    name='payment_lastName'
                                                    type='text'
                                                />
                                                <label>{t('account.payment_lastName')}<sup>*</sup></label>
                                                <span className='error-field'>{props.errors['payment_lastName']}</span>
                                            </div>
                                            </>
                                        )}
                                        {(step === 1) && (
                                          <>
                                              <div className={classNames({ 'field-inp': true, full: true, val: props.values.payment_cardNumber })}>
                                                  <NumberFormat
                                                      format='#### #### #### ####'
                                                      name='payment_cardNumber'
                                                      value={props.values.payment_cardNumber}
                                                      displayType={'input'}
                                                      onChange={e => props.setFieldValue(e.target.name, e.target.value)}
                                                  />
                                                  <label>{t('account.payment_cardNumber')}<sup>*</sup></label>
                                                  <span className='error-field'>{props.errors['payment_cardNumber']}</span>
                                              </div>
                                              <div className={classNames({ 'field-inp': true, sm: true, xs: true, val: props.values.payment_expiry })}>
                                                  <NumberFormat
                                                      format={cardExpiry}
                                                      name='payment_expiry'
                                                      value={props.values.payment_expiry}
                                                      displayType={'input'}
                                                      mask={['M', 'M', 'Y', 'Y']}
                                                      onChange={e => props.setFieldValue(e.target.name, e.target.value)}
                                                  />
                                                  <label>{t('account.payment_expiry')}<sup>*</sup></label>
                                                  <span className='error-field'>{props.errors['payment_expiry']}</span>
                                              </div>
                                              <div className={classNames({ 'field-inp': true, sm: true, val: props.values.payment_cvv })}>
                                                  <NumberFormat
                                                      format='###'
                                                      name='payment_cvv'
                                                      value={props.values.payment_cvv}
                                                      displayType={'input'}
                                                      onChange={e => props.setFieldValue(e.target.name, e.target.value)}
                                                  />
                                                  <label>{t('account.payment_cvv')}<sup>*</sup></label>
                                                  <span className='error-field'>{props.errors['payment_cvv']}</span>
                                              </div>
                                          </>
                                        )}
                                        {step !== 3 && (
                                            <>
                                                <div className={classNames({ 'field-inp': true, full: true, val: props.values.payment_address_line_one })}>
                                                    <Field
                                                        component='input'
                                                        id='payment_address_line_one'
                                                        name='payment_address_line_one'
                                                        type='text'
                                                    />
                                                    <label>{t('account.payment_address_line_one')}<sup>*</sup></label>
                                                    <span className='error-field'>{props.errors['payment_address_line_one']}</span>
                                                </div>
                                                <div className={classNames({ 'field-inp': true, full: true, val: props.values.payment_address_line_two })}>
                                                    <Field
                                                        component='input'
                                                        id='payment_address_line_two'
                                                        name='payment_address_line_two'
                                                        type='text'
                                                    />
                                                    <label>{t('account.payment_address_line_two')}</label>
                                                </div>
                                                <div className={classNames({ 'field-inp': true, val: props.values.payment_city })}>
                                                    <Field
                                                        component='input'
                                                        id='payment_city'
                                                        name='payment_city'
                                                        type='text'
                                                    />
                                                    <label>{t('account.payment_city')}<sup>*</sup></label>
                                                    <span className='error-field'>{props.errors['payment_city']}</span>
                                                </div>
                                                <div className={classNames({ 'field-inp': true, val: props.values.payment_zip })}>
                                                    <Field
                                                        component='input'
                                                        id='payment_zip'
                                                        name='payment_zip'
                                                        type='text'
                                                    />
                                                    <label>{t('account.payment_zip')}<sup>*</sup></label>
                                                    <span className='error-field'>{props.errors['payment_zip']}</span>
                                                </div>
                                            </>
                                        )}
                                        <div className='confirm'>
                                            <input
                                                className='styled-checkbox'
                                                id='styled-checkbox-1'
                                                type='checkbox'
                                                checked={props.values.confirm}
                                                name='confirm'
                                                onChange={() => props.values.confirm ? props.setFieldValue('confirm', false) : props.setFieldValue('confirm', true)} />
                                            <label htmlFor='styled-checkbox-1'>I confirm authorization Kameon to save this payment method and automatically charge this payment.</label>
                                            <span className='error-field'>{props.errors['confirm']}</span>
                                        </div>
                                    </div>
                                </ScrollWrapper>
                            </React.Fragment>
                        )}
                    </Wizard.Page>
                </Wizard>
            </div>
            {errorSubmit && <div className='response-submit'>{t('error.something_wrong_please_try_again')}</div>}
        </div>
    );
};

InfoCard.propTypes = {
    t: PropTypes.func,
    formEmail: PropTypes.string,
    employees: PropTypes.object,
    actions: PropTypes.shape({
        getEmployees: PropTypes.func,
        submitCart: PropTypes.func,
        clearSubmit: PropTypes.func,
    }),
    cart: PropTypes.array,
    settings: PropTypes.object,
    promoCode: PropTypes.string,
    errorSubmit: PropTypes.bool,
    loadingSubmit: PropTypes.bool,
};

/**
 *
 * @param state
 * @returns {{settings: *, errorSubmit: Requireable<string> | boolean, formEmail: Requireable<string> | null, promoCode: *, employees: (string|Requireable<Object>|initialState.employees|{}|Yt.employees), loadingSubmit: Requireable<boolean> | boolean | *}}
 */
function mapStateToProps(state) {
    return {
        formEmail: state.calculator.formEmail,
        employees: state.calculator.employees,
        settings: state.calculator.settings,
        promoCode: state.calculator.promoCode,
        loadingSubmit: state.calculator.loadingSubmit,
        errorSubmit: state.calculator.errorSubmit,
    };
}

/**
 *
 * @param dispatch
 * @returns {{actions: ({clearSubmit: clearSubmit, submitCart: submitCart, getEmployees: getEmployees}|ActionCreator<any>|ActionCreatorsMapObject<any>)}}
 */
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            getEmployees,
            submitCart,
            clearSubmit,
        }, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(InfoCard));
