import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { setTotalPrice } from '../../../actions/calculator';

import { monthNames, formatPrice } from '../../../constants';

import { renderIcon } from '../../../utils/renderIcon';

import './styles.scss';

const TotalBox = ({ t, trial, settings, checkbox, totalPrice, products, cart, discount, isPromocodeDiscount, actions: { setTotalPrice } }) => {
    const [date, setDate] = useState(new Date());
    const { countUser } = settings;

    useEffect(() => {
        setDate(new Date(date.getTime() + (trial * (1000 * 60 * 60 * 24))));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let allProducts = [];

        let priceModule = 0;

        Object.keys(products).forEach(item => {
            allProducts = [...allProducts, ...products[item].list];
        });

        allProducts.filter(item => cart.indexOf(item.id) !== -1).forEach(item => {
            if(checkbox) {
                priceModule = priceModule + item.price;
            } else {
                priceModule = priceModule + item.priceAnnual;
            }
        });

        setTotalPrice((isNaN(countUser) ? priceModule : countUser * priceModule) - (((isNaN(countUser) ? 1 : countUser) * priceModule) * discount / 100));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cart, checkbox, countUser, discount]);

    return (
        <div className='total-box'>
            <div className='left'>
                <div className='total'>{t('cart.total')}:</div>
                {isPromocodeDiscount && discount && (<div className='pay'>{t('cart.pay_on')}&nbsp;<b>{monthNames[date.getMonth()]} {date.getDate()}, {date.getFullYear()}</b></div>)}
                {isPromocodeDiscount && discount && (<div className='trial'>
                    {t('cart.charged_after')} {trial} {t('cart.days_trial')}
                </div>)}
            </div>
            <div className='right'>
                <div>{totalPrice && totalPrice.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}{isPromocodeDiscount && discount && (<span className='star'>*</span>)}</div>
                {isPromocodeDiscount && discount && (
                    <div className='discount'>
                        <div className='icon'>{renderIcon('discount')}</div>
                        {discount}% {t('cart.discount_included')}
                    </div>
                )}
            </div>
        </div>
    );
};

TotalBox.propTypes = {
    t: PropTypes.func,
    trial: PropTypes.number,
    totalPrice: PropTypes.number,
    products: PropTypes.array,
    cart: PropTypes.array,
    checkout: PropTypes.bool,
    isPromocodeDiscount: PropTypes.bool,
    settings: PropTypes.shape({
        priceForUsers: PropTypes.number,
    }),
    actions: PropTypes.shape({
        setTotalPrice: PropTypes.func,
    }),
    discount: PropTypes.number,
};

/**
 *
 * @param state
 * @returns {{settings: *, totalPrice: Requireable<number> | null, isPromocodeDiscount: (boolean|Requireable<(...args: any[]) => any>|isPromocode), discount: Requireable<number> | null, trial: Requireable<number> | null, cart: *, products: {}}}
 */
function mapStateToProps(state) {
    return {
        trial: state.calculator.trial,
        settings: state.calculator.settings,
        checkbox: state.calculator.settings.checkbox,
        totalPrice: state.calculator.totalPrice,
        products: state.calculator.products,
        cart: state.calculator.cart,
        discount: state.calculator.discount,
        isPromocodeDiscount: state.calculator.isPromocode,
    };
}

/**
 *
 * @param dispatch
 * @returns {{actions: ({setTotalPrice: setTotalPrice})}}
 */
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            setTotalPrice,
        }, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TotalBox));
