import {isInteger} from "formik";

export * from './calculator';
export const LOCALE = {
    EN: 'en',
    DE: 'de',
};

export const LOCALES = [
    LOCALE.EN,
    LOCALE.DE,
];

export const k_format = num => {
    return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'k' : Math.sign(num) * Math.abs(num);
};

export const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December',
];

export const formatPrice = (price) => {
    const currencySign = '€';

    if(Number.isInteger(price)) {
        return `${price.toLocaleString('de-DE')} ${currencySign}`;
    }

    return price.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' });
};
