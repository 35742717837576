import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';
import * as PropTypes from 'prop-types';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { withTranslation } from 'react-i18next';

import Calculator from '../../ui/calculator';
import ProductList from '../../ui/listProducts';
import Loader from '../../ui/loader';
import ModalCalculator from '../../ui/modals/calculator';
import StickyCart from '../../ui/stickyCart';

import { renderIcon } from '../../../utils/renderIcon';

import { getPrice, getProducts, isLogin, getTrial, isPromocode } from '../../../actions/calculator';

import 'react-tabs/style/react-tabs.css';
import './styles.scss';

const Dashboard = ({ t, actions: { getProducts, getPrice, isLogin, getTrial, isPromocode }, loadingProducts, products }) => {
    useEffect(() => {
        getProducts();
        getPrice();
        isLogin();
        getTrial();
        isPromocode();

        const products = document.querySelector('.dashboard .wrap .products-box');
        const shadow = document.querySelector('.dashboard .wrap .bg-shadow');

        shadow.setAttribute('style', `left: ${products.getBoundingClientRect().left}px; width: ${products.getBoundingClientRect().width}px`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className='dashboard'>
            <ModalCalculator modal={true} />
            <div className='wrap'>
                <div className='main-tabs'>
                    <Tabs>
                        <TabList>
                            <Tab>
                                <div className='icon'>{renderIcon('cloud')}</div>
                                <div className='name'>{t('calculator.tab_first')}</div>
                            </Tab>
                            <Tab disabled>
                                <div className='icon'>{renderIcon('dns')}</div>
                                <div className='name'>{t('calculator.tab_second')}</div>
                            </Tab>
                        </TabList>
                        <TabPanel>
                            <Calculator />
                        </TabPanel>
                        <TabPanel />
                    </Tabs>
                </div>
                <div className='bg-shadow' />
                <div className='content'>
                <div className='products-box'>
                    <div className='title'>{t('products.title')}</div>
                    {loadingProducts ? (
                        <Loader />
                        ) : (
                        <div className='products-tabs'>
                            <Tabs>
                                <TabList>
                                    {Object.keys(products).map((item, index) => {
                                        return (
                                            <Tab className={classNames({ 'react-tabs__tab': true, open : Object.keys(products).length > 1 })} key={index}><div className='name'>{products[item].title}</div></Tab>
                                        );
                                    })}
                                </TabList>
                                {Object.keys(products).map((item, index) => {
                                    return (
                                        <TabPanel key={index}>
                                            <ProductList product={products[item].list} />
                                        </TabPanel>
                                    );
                                })}
                            </Tabs>
                        </div>
                    )}
                </div>
                    <StickyCart />
                </div>
            </div>
        </div>
    );
};

Dashboard.propTypes = {
    t: PropTypes.func,
    loadingProducts: PropTypes.bool,
    products: PropTypes.array,
    actions: PropTypes.shape({
        getProducts: PropTypes.func,
        getPrice: PropTypes.func,
        isLogin: PropTypes.func,
        getTrial: PropTypes.func,
        isPromocode: PropTypes.func,
    }),
};

/**
 *
 * @param state
 * @returns {{loadingProducts: Requireable<boolean> | boolean, products: *}}
 */
function mapStateToProps(state) {
    return {
        loadingProducts: state.calculator.loadingProducts,
        products: state.calculator.products,
    };
}

/**
 *
 * @param dispatch
 * @returns {{actions: ({isLogin: isLogin, getTrial: getTrial, getProducts: getProducts, getPrice: getPrice}|ActionCreator<any>|ActionCreatorsMapObject<any>)}}
 */
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            getProducts,
            getPrice,
            isLogin,
            getTrial,
            isPromocode,
        }, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Dashboard));
