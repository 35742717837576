import { callApi } from '../utils/client';
import * as types from '../constants';

/**
 *
 * @returns {function(*): Promise<Response | never | never>}
 */
export function getPrice() {
    return dispatch => {
        dispatch({
            type: types.FETCH_GET_PRICE,
        });

        return callApi('json/price.json', 'GET')
            .then(json => {
                dispatch({
                    type: types.FETCH_GET_PRICE_SUCCESS,
                    payload: json.plans,
                });
            }).catch(error => {
                dispatch({
                    type: types.FETCH_GET_PRICE_FAIL,
                    payload: error.message,
                });
            });
    };
}

/**
 *
 * @returns {function(*): Promise<Response | never | never>}
 */
export function getTrial() {
    return dispatch => {
        dispatch({
            type: types.FETCH_GET_TRIAL,
        });

        return callApi('json/price.json', 'GET')
            .then(json => {
                dispatch({
                    type: types.FETCH_GET_TRIAL_SUCCESS,
                    payload: json.trial,
                });
            }).catch(error => {
                dispatch({
                    type: types.FETCH_GET_TRIAL_FAIL,
                    payload: error.message,
                });
            });
    };
}

/**
 *
 * @returns {function(*): Promise<Response | never | never>}
 */
export function getLinkMenu() {
    return dispatch => {
        dispatch({
            type: types.FETCH_MENU_URL,
        });

        return callApi('json/menu.json', 'GET')
            .then(json => {
                dispatch({
                    type: types.FETCH_MENU_URL_SUCCESS,
                    payload: json,
                });
            }).catch(error => {
                dispatch({
                    type: types.FETCH_MENU_URL_FAIL,
                    payload: error.message,
                });
            });
    };
}

/**
 *
 * @returns {function(*): Promise<Response | never | never>}
 */
export function getProducts() {
    return dispatch => {
        dispatch({
            type: types.FETCH_PRODUCTS,
        });

        return callApi('json/products.json', 'GET')
            .then(json => {
                dispatch({
                    type: types.FETCH_PRODUCTS_SUCCESS,
                    payload: json,
                });
            }).catch(error => {
                dispatch({
                    type: types.FETCH_PRODUCTS_FAIL,
                    payload: error.message,
                });
            });
    };
}

/**
 *
 * @returns {function(*): Promise<Response | never | never>}
 */
export function isValidPromo(field) {
    return dispatch => {
        dispatch({
            type: types.FETCH_PROMO,
        });

        // return callApi('json/promocode.json', 'POST', { promoCode: field })
        return callApi('json/promocode.json')
            .then(json => {
                dispatch({
                    type: types.FETCH_PROMO_SUCCESS,
                    payload: json,
                });
                savePromo(field)(dispatch);
            }).catch(error => {
                dispatch({
                    type: types.FETCH_PROMO_FAIL,
                    payload: error.message,
                });
            });
    };
}

/**
 *
 * @param fields
 * @param callback
 * @returns {function(*): Promise<Response | never | never>}
 */
export function submitCart(fields, callback) {
    return dispatch => {
        dispatch({
            type: types.FETCH_SUBMIT_CART,
        });

        return callApi('https://maxtest.sapient.tools/submit.php', 'POST', fields)
            .then(json => {
                dispatch({
                    type: types.FETCH_SUBMIT_CART_SUCCESS,
                    payload: json,
                });
                if (callback) {
                    callback();
                }
            }).catch(() => {
                dispatch({
                    type: types.FETCH_SUBMIT_CART_FAIL,
                    payload: true,
                });
            });
    };
}

/**
 *
 * @returns {function(*): Promise<Response | never | never>}
 */
export function getEmployees() {
    return dispatch => {
        dispatch({
            type: types.FETCH_GET_EMPLOYEES,
        });

        return callApi('json/price.json', 'GET')
            .then(json => {
                dispatch({
                    type: types.FETCH_GET_EMPLOYEES_SUCCESS,
                    payload: json,
                });
            }).catch(() => {
                dispatch({
                    type: types.FETCH_GET_EMPLOYEES_FAIL,
                    payload: true,
                });
            });
    };
}

/**
 *
 * @returns {Function}
 */
export function clearSubmit() {
    return dispatch => {
        dispatch({
            type: types.FETCH_CLEAR_SUBMIT,
            payload: false,
        });
    };
}

/**
 *
 * @param field
 * @returns {Function}
 */
export function savePromo(field) {
    return dispatch => {
        dispatch({
            type: types.FETCH_SAVE_PROMO,
            payload: field,
        });
    };
}

/**
 *
 * @returns {function(*): Promise<Response | never | never>}
 */
export function isLogin() {
    return dispatch => {
        dispatch({
            type: types.FETCH_LOGIN,
        });

        return callApi('json/login.json', 'GET')
            .then(json => {
                dispatch({
                    type: types.FETCH_LOGIN_SUCCESS,
                    payload: json,
                });
            }).catch(error => {
                dispatch({
                    type: types.FETCH_LOGIN_FAIL,
                    payload: error.message,
                });
            });
    };
}

/**
 *
 * @returns {function(*): Promise<Response | never>}
 */
export function isPromocode() {
    return dispatch => {
        dispatch({
            type: types.FETCH_IS_PROMOCODE,
        });

        return callApi('json/price.json', 'GET')
            .then(json => {
                dispatch({
                    type: types.FETCH_IS_PROMOCODE_SUCCESS,
                    payload: json.isPromocode,
                });
            }).catch(error => {
                dispatch({
                    type: types.FETCH_IS_PROMOCODE_FAIL,
                    payload: error.message,
                });
            });
    };
}

/**
 *
 * @param field
 * @returns {function(*): Promise<Response | never | never>}
 */
export function isAuth(field) {
    return dispatch => {
        dispatch({
            type: types.FETCH_IS_AUTH,
        });

        // return callApi('json/isAuth.json', 'POST', field)
        return callApi('json/isAuth.json', 'GET')
            .then(json => {
                if (json.error ) {
                    throw json;
                }
                dispatch({
                    type: types.FETCH_IS_AUTH_SUCCESS,
                    payload: field.email,
                });
            }).catch(error => {
                dispatch({
                    type: types.FETCH_IS_AUTH_FAIL,
                    payload: error,
                });
            });
    };
}

/**
 *
 * @returns {Function}
 */
export function clearError() {
    return dispatch => {
        dispatch({
            type: types.CLEAR_FORM_EMAIL,
        });
    };
}
/**
 *
 * @param flag
 * @returns {Function}
 */
export function settingsCheckbox(flag) {
    return dispatch => {
        dispatch({
            type: types.FETCH_SETTINGS_CHECKBOX,
            payload: flag,
        });
    };
}

/**
 *
 * @param count
 * @returns {Function}
 */
export function settingsUsers(count) {
    return dispatch => {
        dispatch({
            type: types.FETCH_SETTINGS_USERS,
            payload: count,
        });
    };
}

/**
 *
 * @param percent
 * @returns {Function}
 */
export function settingsUsersPercent(percent) {
    return dispatch => {
        dispatch({
            type: types.FETCH_SETTINGS_USERS_PERCENT,
            payload: percent,
        });
    };
}

/**
 *
 * @param price
 * @returns {Function}
 */
export function settingsPriceForUsers(price) {
    return dispatch => {
        dispatch({
            type: types.FETCH_SETTINGS_PRICE_FOR_USERS,
            payload: price,
        });
    };
}

/**
 *
 * @param points
 * @returns {Function}
 */
export function settingsPoints(points) {
    return dispatch => {
        dispatch({
            type: types.FETCH_SETTINGS_POINTS,
            payload: points,
        });
    };
}

/**
 *
 * @param field
 * @returns {Function}
 */
export function settingsMonthly(field) {
    return dispatch => {
        dispatch({
            type: types.FETCH_SETTINGS_MONTHLY,
            payload: field,
        });
    };
}

/**
 *
 * @param id
 * @returns {Function}
 */
export function addToCart(id) {
    return dispatch => {
        dispatch({
            type: types.FETCH_ADD_TO_CART,
            payload: id,
        });
    };
}

/**
 *
 * @param id
 * @returns {Function}
 */
export function removeFromCart(id) {
    return dispatch => {
        dispatch({
            type: types.FETCH_REMOVE_FROM_CART,
            payload: id,
        });
    };
}

/**
 *
 * @param price
 * @returns {Function}
 */
export function setTotalPrice(price) {
    return dispatch => {
        dispatch({
            type: types.FETCH_SET_TOTAL_PRICE,
            payload: price,
        });
    };
}
