import * as types from '../constants';

export const initialState = {
    price: {},
    loadingPrice: true,
    menuLink: [],
    loadingProducts: true,
    products: {},
    settings: {
        checkbox: true,
        countUser: 1,
        countUserPercent: [0],
        priceForUsers: 0,
        points: [],
        monthly: [],
    },
    cart: [],
    login: {},
    trial: null,
    totalPrice: 0,
    promoCode: '',
    loadingPromoCode: false,
    discount: null,
    loadingIsAuth: false,
    formEmail: null,
    errorFormEmail: null,
    loadingSubmit: false,
    errorSubmit: false,
    employees: {},
    isPromocode: false,
};

/**
 *
 * @param state
 * @param action
 * @returns {{price: {}, loadingPrice: boolean}}
 */
export default function calculator(state = initialState, action) {
    switch (action.type) {
        case types.FETCH_GET_EMPLOYEES:
            return {
                ...state,
            };
        case types.FETCH_SUBMIT_CART:
            return {
                ...state,
                loadingSubmit: true,
            };
        case types.FETCH_IS_AUTH:
            return {
                ...state,
                loadingIsAuth: true,
            };
        case types.FETCH_IS_PROMOCODE_SUCCESS:
            return {
                ...state,
                isPromocode: action.payload,
            };
        case types.FETCH_PROMO:
            return {
                ...state,
                loadingPromoCode: true,
            };
        case types.FETCH_CLEAR_SUBMIT:
            return {
                ...state,
                errorSubmit: false,
            };
        case types.FETCH_SAVE_PROMO:
            return {
                ...state,
                promoCode: action.payload,
            };
        case types.FETCH_ADD_TO_CART:
            return {
                ...state,
                cart: state.cart.indexOf(action.payload) === -1 ? [...state.cart, action.payload] : state.cart,
            };
        case types.FETCH_REMOVE_FROM_CART:
            return {
                ...state,
                cart:  state.cart.filter(item => item !== action.payload),
            };
        case types.FETCH_SET_TOTAL_PRICE:
            return {
                ...state,
                totalPrice: action.payload,
            };
        case types.FETCH_GET_PRICE:
            return {
                ...state,
                loadingPrice: true,
            };
        case types.FETCH_MENU_URL:
            return {
                ...state,
            };
        case types.FETCH_PRODUCTS:
            return {
                ...state,
                loadingProducts: true,
            };
        case types.FETCH_SETTINGS_CHECKBOX:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    checkbox: action.payload,
                },
            };
        case types.FETCH_SETTINGS_USERS:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    countUser: action.payload,
                },
            };
        case types.FETCH_SETTINGS_USERS_PERCENT:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    countUserPercent: action.payload,
                },
            };
        case types.FETCH_SETTINGS_PRICE_FOR_USERS:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    priceForUsers: action.payload,
                },
            };
        case types.FETCH_SETTINGS_POINTS:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    points: action.payload,
                },
            };
        case types.FETCH_SETTINGS_MONTHLY:
            return {
               ...state,
                settings: {
                    ...state.settings,
                    monthly: action.payload,
                },
            };
        case types.FETCH_IS_AUTH_SUCCESS:
            return {
                ...state,
                formEmail: action.payload,
                loadingIsAuth: false,
            };
        case types.FETCH_GET_EMPLOYEES_SUCCESS:
            return {
                ...state,
                employees: action.payload.employees,
            };
        case types.FETCH_PROMO_SUCCESS:
            return {
                ...state,
                discount: action.payload.discount,
                loadingPromoCode: false,
            };
        case types.FETCH_GET_TRIAL_SUCCESS:
            return {
                ...state,
                trial: action.payload,
            };
        case types.FETCH_LOGIN_SUCCESS:
            return {
                ...state,
                login: action.payload,
            };
        case types.FETCH_SUBMIT_CART_SUCCESS:
            return {
                ...state,
                loadingSubmit: false,
            };
        case types.FETCH_PRODUCTS_SUCCESS:
            return {
                ...state,
                loadingProducts: false,
                products: action.payload,
            };
        case types.FETCH_GET_PRICE_SUCCESS:
            return {
                ...state,
                price: action.payload,
                loadingPrice: false,
            };
        case types.FETCH_MENU_URL_SUCCESS:
            return {
                ...state,
                menuLink: action.payload,
            };
        case types.FETCH_PROMO_FAIL:
            return {
                ...state,
                loadingPromoCode: false,
            };
        case types.FETCH_GET_PRICE_FAIL:
            return {
                ...state,
                loadingPrice: false,
            };
        case types.FETCH_IS_AUTH_FAIL:
            return {
                ...state,
                errorFormEmail: action.payload.error,
                loadingIsAuth: false,
            };
        case types.CLEAR_FORM_EMAIL:
            return {
                ...state,
                errorFormEmail: null,
            };
        case types.FETCH_SUBMIT_CART_FAIL:
            return {
                ...state,
                errorSubmit: action.payload,
                loadingSubmit: false,
            };
        case types.FETCH_PRODUCTS_FAIL:
            return {
                ...state,
                loadingProducts: false,
            };
        default:
            return state;
    }
}
