import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';
import * as PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { formatPrice } from "../../../constants";

import Promo from '../../ui/promo';
import TotalBox from '../../ui/totalBox';
import Loader from '../../ui/loader';
import EmptyCart from '../../ui/emptyCart';
import ScrollWrapper from '../../ui/scrollWrapper';

import { removeFromCart, submitCart, clearSubmit } from '../../../actions/calculator';

import history from '../../../utils/history';
import { renderIcon } from '../../../utils/renderIcon';

import './styles.scss';

const StickyCart = ({
    t,
    products,
    cart,
    settings,
    promoCode,
    loadingSubmit,
    errorSubmit,
    checkbox,
    isPromocodeDiscount,
    actions: {
        removeFromCart,
        submitCart,
        clearSubmit,
    },
    isLogin,
}) => {
    const { login, link } = isLogin;
    const [fixed, setFixed] = useState(false);
    const [cartItems, setCartItems] = useState([]);

    useEffect(() => {
        const calculator = document.querySelector('.main-tabs .calculator');
        const cartRight = document.querySelector('.dashboard .wrap .content');
        const stickyCart = document.querySelector('.dashboard .sticky-cart');
        const scrollToElem = () => {
            const currentScroll = window.pageYOffset;
            const positionCart = cartRight.getBoundingClientRect().left;

            if (currentScroll > (calculator.offsetTop + calculator.getBoundingClientRect().height)) {
                setFixed(true);
                stickyCart.setAttribute('style', `right: ${positionCart}px`);
            } else {
                setFixed(false);
                stickyCart.setAttribute('style', 'right: 0px');
            }
        };

        window.addEventListener('scroll', scrollToElem);

        return () => window.removeEventListener('scroll', scrollToElem);
    });

    useEffect(() => {
        if (errorSubmit) {
            setTimeout(() => {
                clearSubmit();
            }, 2000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorSubmit]);

    useEffect(() => {
        let allProducts = [];

        Object.keys(products).forEach(item => {
            allProducts = [...allProducts, ...products[item].list];
        });

        setCartItems(allProducts.filter(item => cart.indexOf(item.id) !== -1));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cart]);

    const callback = () => {
        setTimeout(() => {
            window.location.hash = 'thank-you';
            // history.push('thank-you');
        }, 400);
    };

    const submit = () => {
        if(login) {
            const listCart = {};

            listCart.isMonthly = settings.checkbox;
            listCart.quantityOfUsers = settings.countUser;
            listCart.products = cart;
            listCart.promoCode = promoCode;

            submitCart(listCart, callback);
        }else {
            window.location.hash = 'checkout';
            // history.push('/#/');
        }
    };

    return (
        <div className={classNames({ 'sticky-cart': true, fixed })}>
            <div className='head-cart'>
                <div className='names'>{t('cart.you_have_selected')}:</div>
                {isPromocodeDiscount && (
                    <div className='promo-box'><Promo /></div>
                )}
            </div>
            <div className='box-cart'>
                {cartItems.length > 0 && (
                    <ScrollWrapper>
                        <div className='list-items'>
                            {cartItems && cartItems.map(item => {
                                return (
                                    <div className='item' key={item.id}>
                                        {!item.inCart && (
                                            <div className='icon' onClick={() => removeFromCart(item.id)}>{renderIcon('close')}</div>
                                        )}
                                        <div className='info'>
                                            <div className='name'>{item.name}</div>
                                            <div className='price'>
                                                <div>{
                                                    checkbox ? (isNaN(settings.countUser) ? formatPrice(item.price) : formatPrice(settings.countUser * item.price))
                                                    : (
                                                        isNaN(settings.countUser) ? formatPrice(item.priceAnnual) : formatPrice(settings.countUser * item.priceAnnual)
                                                    )}</div>
                                                <div>/ mo.</div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </ScrollWrapper>
                )}
                {cartItems.length === 0 && <div className='empty-cart-container'><EmptyCart /></div>}
            </div>
            <div className='total-bottom'>
                <TotalBox />
            </div>
            {loadingSubmit && (
                <div className='load-box'>
                    <Loader medium />
                </div>
            )}
            <div className='link' onClick={() => submit()}>{t('cart.checkout')}</div>
            {isLogin && !login && (
                <div className='under-text'>
                    <a href={link}>{t('cart.login')}</a>&nbsp;
                    {t('cart.to_upgrade_or_add_to_your_existing_subscription')}.
                </div>
            )}
            {errorSubmit && <div className='response-submit'>{t('error.something_wrong_please_try_again')}</div>}
        </div>
    );
};

StickyCart.propTypes = {
    t: PropTypes.func,
    products: PropTypes.array,
    cart: PropTypes.array,
    isPromocodeDiscount: PropTypes.bool,
    settings: PropTypes.shape({
        priceForUsers: PropTypes.number,
    }),
    actions: PropTypes.shape({
        removeFromCart: PropTypes.func,
        clearSubmit: PropTypes.func,
        submitCart: PropTypes.func,
    }),
    isLogin: PropTypes.shape({
        login: PropTypes.bool,
        link: PropTypes.string,
    }),
};

/**
 *
 * @param state
 * @returns {{isLogin: Requireable<boolean> | initialState.login | {} | jt.login, cart: *, products: Requireable<Object> | initialState.products | {} | jt.products}}
 */
function mapStateToProps(state) {
    return {
        products: state.calculator.products,
        cart: state.calculator.cart,
        isLogin: state.calculator.login,
        settings: state.calculator.settings,
        promoCode: state.calculator.promoCode,
        loadingSubmit: state.calculator.loadingSubmit,
        errorSubmit: state.calculator.errorSubmit,
        isPromocodeDiscount: state.calculator.isPromocode,
        checkbox: state.calculator.settings.checkbox,
    };
}

/**
 *
 * @param dispatch
 * @returns {{actions: ({removeFromCart: removeFromCart, clearSubmit: clearSubmit, submitCart: submitCart}|ActionCreator<any>|ActionCreatorsMapObject<any>)}}
 */
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            removeFromCart,
            submitCart,
            clearSubmit,
        }, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(StickyCart));

